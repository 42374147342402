<template>
<div id="Incline__container">
  <div id="box">
    <div id="box__content">
      <div id="box__img"></div>
    </div>
  </div>
</div>
</template>

<script>
import { onMounted } from 'vue';

export default {
  name: 'Incline',
  setup () {

    function getDistance (xA, yA, xB, yB) {
      let xDiff = xA - xB;
      let yDiff = yA - yB;

      return Math.sqrt(xDiff * xDiff + yDiff * yDiff);
    }

    onMounted(() => {
      const container = document.querySelector('#Incline__container');
      const box = document.querySelector('#box__content');

      if (module && box) {
        container.onmousemove = event => {
          const _box = box.getBoundingClientRect();
          let _container = container.getBoundingClientRect();
          let box_half_width = _container.width / 2;
          let box_half_height = _container.height / 2;
          let maxDeg = 20;
          let mouseX = event.x - _container.left;
          let mouseY = event.y - _container.top;

          let distance_center = getDistance(box_half_width, box_half_height, mouseX, mouseY);

          let scale = distance_center <= 500 ? 1 + ((distance_center - 500) / 1500) : 1;

          let value_y = (((mouseX - box_half_width) * maxDeg) / 250);
          let value_x = -(((mouseY - box_half_height) * maxDeg) / 250);
          box.style.transform = `rotateX(${value_x}deg) rotateY(${value_y}deg) scale(${scale})`;

          let window_innerWidth = window.innerWidth;
          let window_innerHeight = window.innerHeight;

          let x_ratio = (2109 - _box.width - 230) / window_innerWidth;
          let y_ratio = (2574 - _box.height - 120) / window_innerHeight;

          let offsetX = (window_innerWidth - event.x) * x_ratio;
          let offsetY = (window_innerHeight - event.y) * y_ratio;
          box.style.backgroundPosition = `${-offsetX}px ${-offsetY}px`;
        };
      }
    });
  },
};
</script>

<style lang="stylus" scoped>
#Incline__container
  position absolute
  overflow hidden
  z-index 100
  top 0
  right 0
  bottom 0
  left 0
  display flex
  align-items center
  justify-content center
  #box
    position absolute
    width 550px
    height 400px
    perspective 1000px
    transform-style preserve-3d
    &__content
      position absolute
      z-index -1
      top 0
      left 0
      bottom 0
      right 0
      border-radius 20px
      transform-style preserve-3d
      background-image url('../../public/incline/_still_image.webp')
      background-position top center
      background-repeat no-repeat
      background-size auto
      box-shadow 0 5rem 10rem rgba(0, 0, 0, 0.4)
    &__img
      position absolute
      top 10px
      left 10px
      bottom 10px
      right 10px
      transform translateZ(200px)
      background-image url('../../public/incline/goldenFrame.png')
      background-repeat no-repeat
      background-position center
      background-size contain
      border-radius 20px
</style>